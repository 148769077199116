import React, { useLayoutEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

function Highmap() {

    useLayoutEffect(() => {
        

        let data = Highcharts.geojson(Highcharts.maps['countries/in/in-all']);
        const separators = Highcharts.geojson(Highcharts.maps['countries/in/in-all'], 'mapline');

        // Set drilldown pointers
        data.forEach((d, i) => {
            d.drilldown = d.properties['hc-key'];
            d.value = i; // Non-random bogus data
        });

        function getScript(url, cb) {
            const script = document.createElement('script');
            script.src = url;
            script.onload = cb;
            document.head.appendChild(script);
        }

        // Instantiate the map
        Highcharts.mapChart('Highmap', {
            chart: {
                events: {
                    drilldown: function (e) {
                        if (!e.seriesOptions) {
                            const chart = this,
                                mapKey = 'countries/in/' + e.point.drilldown + '-all';

                            // Handle error, the timeout is cleared on success
                            let fail = setTimeout(() => {
                                if (!Highcharts.maps[mapKey]) {
                                    chart.showLoading('<i class="icon-frown"></i> Failed loading ' + e.point.name);
                                    fail = setTimeout(() => {
                                        chart.hideLoading();
                                    }, 1000);
                                }
                            }, 3000);

                            // Show the spinner
                            chart.showLoading('<i class="icon-spinner icon-spin icon-3x"></i>'); // Font Awesome spinner

                            // Load the drilldown map
                            getScript('https://code.highcharts.com/mapdata/' + mapKey + '.js', () => {
                                data = Highcharts.geojson(Highcharts.maps[mapKey]);

                                // Set a non-random bogus value
                                data.forEach((d, i) => {
                                    d.value = i;
                                });

                                // Hide loading and add series
                                chart.hideLoading();
                                clearTimeout(fail);
                                chart.addSeriesAsDrilldown(e.point, {
                                    name: e.point.name,
                                    data: data,
                                    dataLabels: {
                                        enabled: true,
                                        format: '{point.name}'
                                    }
                                });
                            });
                        }
                    }
                }
            },

            title: {
                text: 'Highcharts Map Drilldown'
            },

            colorAxis: {
                min: 0,
                minColor: '#1ddb20',
                maxColor: '#db1d1d'
            },

            mapNavigation: {
                enabled: true,
                buttonOptions: {
                    verticalAlign: 'bottom'
                }
            },

            plotOptions: {
                map: {
                    states: {
                        hover: {
                            color: '#EEDD66'
                        }
                    }
                }
            },

            series: [{
                data: data,
                name: 'IN',
                dataLabels: {
                    enabled: true,
                    format: '{point.properties.postal-code}'
                }
            }, {
                type: 'mapline',
                data: separators,
                color: 'silver',
                enableMouseTracking: false,
                animation: {
                    duration: 500
                }
            }],

            drilldown: {
                activeDataLabelStyle: {
                    color: '#FFFFFF',
                    textDecoration: 'none',
                    textOutline: '1px #000000'
                },
                drillUpButton: {
                    relativeTo: 'spacingBox',
                    position: {
                        x: 0,
                        y: 60
                    }
                }
            }
        });


        return () => {
            
        };
    }, [])


    return (
        <div>
            <div id="Highmap" style={{width:"100%", height: "70vh"}}></div>
        </div>
    )
}

export default Highmap
