import React from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Dashboard from './components/Dashboard';
import Highmap from './components/Highmap';

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/" element={<Dashboard />}/>
          {/* <Route path="/test" element={<Highmap />}/> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
