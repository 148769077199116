import React from 'react';
import Chart1 from './Chart1';
import Chart2 from './Chart2';
import Chart3 from './Chart3';
import Chart4 from './Chart4';
import Chart5 from './Chart5';
import Chart6 from './Chart6';
import Chart7 from './Chart7';
import Chart8 from './Chart8';
import Highmap from './Highmap';
import Indiamap from './Indiamap';
// import Mapchart from './Mapchart';

function Dashboard() {
    return (
        <React.Fragment>
            <section data-layout="horizontal" data-topbar="light">
                <div id="wrapper">
                    <header id="topnav">
                        <div className="navbar-custom">
                        <div className="container-fluid">
                        <ul className="list-unstyled topnav-menu float-right mb-0">
                        <li className="dropdown notification-list">
                        <a className="navbar-toggle nav-link">
                        <div className="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                        </div>
                        </a>
                        </li>

                        <li className="dropdown notification-list">
                        <a className="nav-link dropdown-toggle nav-user mr-0 waves-effect" data-toggle="dropdown" href="#" role="button" aria-haspopup="false" aria-expanded="false">
                        <img src="assets/images/user.png" alt="user-image" className="rounded-circle" />
                        <span className="pro-user-name ml-1">
                        Administrator <i className="mdi mdi-chevron-down"></i> 
                        </span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-right profile-dropdown ">
                        <a href="user-profile.php" className="dropdown-item notify-item">
                        <i className="fe-user"></i>
                        <span>My Account</span>
                        </a>
                        <div className="dropdown-divider"></div>
                        <a href="index.php" className="dropdown-item notify-item">
                        <i className="fe-log-out"></i>
                        <span>Logout</span>
                        </a>
                        </div>
                        </li>
                        </ul>
                        <div className="logo-box d-flex align-items-center">
                        <a href="#" className="logo logo-dark">
                        <span className="logo-lg">
                        <img src="assets/images/logo.png" alt="" height="60" />
                        </span>
                        </a>
                        </div>
                        <div className="logo-heading-box">
                        <h2>MIS for Sustainable Energy Foundation</h2>
                        </div>
                        <div className="clearfix"></div>
                        </div>
                        </div>
                        <div className="topbar-menu">
                        <div className="container-fluid">
                        <div id="navigation">
                        <ul className="navigation-menu">
                        <li>
                        <a href="dashboard.php"><i className="mdi mdi-speedometer"></i> Dashboard</a>
                        </li>
                        <li className="has-submenu">
                        <a href="#">Household <div className="arrow-down"></div></a>
                        <ul className="submenu">
                        <li><a href="form-listing.php">Household List</a></li>
                        <li><a href="form-add.php">Add Household</a></li>
                        </ul>
                        </li>
                        </ul>

                        <div className="clearfix"></div>
                        </div>
                        </div>
                        </div>
                    </header>
                    <div className="content-page">
                    <div className="content">
                    <div className="container-fluid">
                    <div className="row">
                    <div className="col-sm-12">
                    <div className="row">
                    <div className="col-sm-12">
                    <div className="filter-form mb-3">
                    <form method="get">
                    <div className="row">
                    <div className="col-md-3">
                    <div className="form-group">
                    <select className="form-control">
                    <option value="">Select State</option>
                    </select>
                    </div>
                    </div>
                    <div className="col-md-3">
                    <div className="form-group">
                    <select className="form-control" placeholder="" name="district_id">
                    <option value="">Select District</option>
                    </select>
                    </div>
                    </div>
                    <div className="col-md-2">
                    <div className="form-group">
                    <select className="form-control" placeholder="" name="block_id">
                    <option value="">Select Block</option>
                    </select>
                    </div>
                    </div>
                    <div className="col-md-2">
                    <div className="form-group">
                    <select className="form-control" placeholder="" name="village_id" id="village_id">
                    <option value="">Select Village</option>
                    </select>
                    </div>
                    </div>
                    <div className="col-md-2">
                    <button type="submit" className="btn btn-primary width-md waves-effect waves-light" name="search">Search</button>
                    </div>
                    </div>
                    </form>
                    </div>
                    </div>
                    </div>
                    </div>
                    </div>

                    <div className="row">
                    <div className="col-xl-2 col-md-6">
                    <a href="state_list.php">
                    <div className="card-box small-dash-box">
                    <h4 className="header-title mt-0 mb-1">Total Cities</h4>
                    <div className="widget-inner">
                    <div className="widget-icon dark-blue">
                    <i className="fa fa-globe"></i>
                    </div>
                    <div className="widget-chart">
                    <div className="widget-detail text-right">
                    <h2 className="font-weight-normal mb-1"> 25 </h2>
                    <p className="text-muted mb-1">Registered</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </a>
                    </div>
                    <div className="col-xl-2 col-md-6">
                    <a href="district_list.php">
                    <div className="card-box small-dash-box">
                    <h4 className="header-title mt-0 mb-1">Total Households</h4>
                    <div className="widget-inner">
                    <div className="widget-icon skyblue float-left">
                    <i className="fa fa-city"></i>
                    </div>
                    <div className="widget-chart">
                    <div className="widget-detail text-right">
                    <h2 className="font-weight-normal mb-1"> 12352 </h2>
                    <p className="text-muted mb-1">Registered</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </a>
                    </div>
                    <div className="col-xl-2 col-md-6">
                    <a href="farmer-list.php">
                    <div className="card-box small-dash-box">
                    <h4 className="header-title mt-0 mb-1">Total Villages</h4>
                    <div className="widget-inner">
                    <div className="widget-icon dark-blue float-left">
                    <i className="fa fa-vihara"></i>
                    </div>
                    <div className="widget-chart">
                    <div className="widget-detail text-right">
                    <h2 className="font-weight-normal mb-1">  600</h2>
                    <p className="text-muted mb-1">Registered</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </a>
                    </div>
                    <div className="col-xl-2 col-md-6">
                    <a href="supplier-list.php">
                    <div className="card-box small-dash-box">
                    <h4 className="header-title mt-0 mb-1">Residentials Properties</h4>
                    <div className="widget-inner">
                    <div className="widget-icon orange float-left">
                    <i className="fa fa-home"></i>
                    </div>
                    <div className="widget-chart">
                    <div className="widget-detail text-right">
                    <h2 className="font-weight-normal mb-1"> 1300 </h2>
                    <p className="text-muted mb-1">Survey Completed</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </a>
                    </div>
                    <div className="col-xl-2 col-md-6">
                    <a href="#">
                    <div className="card-box small-dash-box">
                    <h4 className="header-title mt-0 mb-1">Commercial Properties</h4>
                    <div className="widget-inner">
                    <div className="widget-icon yellow float-left">
                    <i className="fa fa-hotel"></i>
                    </div>
                    <div className="widget-chart">
                    <div className="widget-detail text-right">
                    <h2 className="font-weight-normal mb-1"> 1000 </h2>
                    <p className="text-muted mb-1">Survey Completed</p>
                    </div>
                    </div>
                    </div>
                    </div>
                    </a>
                    </div>
                    <div className="col-xl-2 col-md-6">
                        <a href="land_holding.php">
                        <div className="card-box small-dash-box">
                            <h4 className="header-title mt-0 mb-1">No. of Users</h4>
                            <div className="widget-inner">
                                <div className="widget-icon red float-left">
                                    <i className="fa fa-users"></i>
                                </div>
                                <div className="widget-chart">
                                    <div className="widget-detail text-right">
                                        <h2 className="font-weight-normal mb-1"> 120 </h2>
                                        <p className="text-muted mb-1">Registered</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        </a>
                    </div>
                </div>

                    <div className="row">
                        <div className="col-xl-5">
                            <div className="card-box heading-design">
                                <h4 className="header-title mt-0 design-1">India</h4>
                                <div className=" widget-chart text-center">
                                    {/* <Mapchart /> */}
                                    <Indiamap />
                                    {/* <img src="assets/images/graphs/1.jpg" className="img-fluid"/>   */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="row">
                                    
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Socio-Economic Profile</h4>
                                        <div className="widget-chart text-center">
                                            <ul className="list-unstyled text-left list-dashboard">
                                                <li>Population in urban areas : <strong>31%</strong></li>
                                                <li>Population in rural areas : <strong>70%</strong></li>
                                                <li>population density : <strong>382/km2</strong> (990/sq mi)</li>
                                                <li>Gender Ratio: 1000: <strong>995</strong></li>
                                                <li>GDP:</li>
                                                <li>Per Capita Electricity Consumption:</li>
                                            </ul>
                                            <p className="list-dashboard-footer">Year: <strong>2011</strong></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Profile</h4>
                                        <div className="widget-chart text-center">
                                            <ul className="list-unstyled text-left list-dashboard">
                                                <li>Annual Maximum Temperature: <strong>44 °C</strong></li>
                                                <li>Annual Minimum Temperature: <strong>20.76 °C</strong></li>
                                                <li>Rainfall in India: <strong>1,881</strong> (mm)</li>
                                                <li>GHG emission: <strong> 2,597.36</strong></li>
                                                <li>SDG score: <strong>60</strong></li>
                                                <li>Key policies & prog :</li>
                                            </ul>
                                            <p className="list-dashboard-footer">Year: <strong>2010</strong></p>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Growth in GDP Vs. GHG</h4>
                                        <div className="widget-chart text-center">
                                            <Chart1 />
                                            {/* <img src="assets/images/graphs/2.jpg" className="img-fluid"/> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Funding Snapshot (In Last 10 Years)</h4>
                                        <div className="widget-chart text-center">
                                            <Chart2 />
                                            {/* <img src="assets/images/graphs/3.jpg" className="img-fluid"/> */}
                                        </div>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div> 



                    <div className="row"> 
                        <div className="col-xl-5">
                            <div className="card-box heading-design">
                                <h4 className="header-title mt-0 design-1">GHG Emission Intensity and % Reduction WRT 2005 (2005 to 2015) - Blink</h4>
                                <div className="widget-chart text-center">
                                    <Chart3 />
                                    {/* <img src="assets/images/graphs/4.jpg" className="img-fluid"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="row">        
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Sectoral Contribution to GHG Emission (2005 to 2015)</h4>
                                        <div className="widget-chart text-center">
                                            <Chart4 />
                                        {/* <img src="assets/images/graphs/5.jpg" className="img-fluid"/>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Profile</h4>
                                        <div className="widget-chart text-center">
                                            <Chart5 />
                                            {/* <img src="assets/images/graphs/6.jpg" className="img-fluid"/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 

                    <div className="row"> 
                        <div className="col-xl-5">
                            <div className="card-box heading-design">
                                <h4 className="header-title mt-0 design-1">Energy (Growth Vs. GHG Emission in %)</h4>
                                <div className="widget-chart text-center">
                                    <Chart6 />
                                    {/* <img src="assets/images/graphs/7.jpg" className="img-fluid"/> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">
                            <div className="row">        
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Sectoral Contribution to GHG Emission (2005 to 2015)</h4>
                                        <div className="widget-chart text-center">
                                            <Chart7 />
                                        {/* <img src="assets/images/graphs/8.jpg" className="img-fluid"/>  */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6">
                                    <div className="card-box heading-design">
                                        <h4 className="header-title mt-0 design-1">Agriculture (Growth Vs. GHG Emission in %)</h4>
                                        <div className="widget-chart text-center">
                                            <Chart8 />
                                            {/* <img src="assets/images/graphs/9.jpg" className="img-fluid"/> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 



                    <div className="row"> 
                        <div className="col-xl-5">
                            <div className="card-box heading-design">
                                <h4 className="header-title mt-0 design-1">Focus Area for Intervention</h4>
                                <div className="widget-chart text-center">
                                    <h5 className="text-left">Sectors:</h5>
                                    <ul className="list-unstyled text-left list-dashboard">
                                        
                                        <li> AFOLU (Icon) - on clicking, it will open an window</li>
                                        <li> Energy</li>
                                        <li> Industry (Icon)</li>
                                        <li> Transport: EV</li>
                                        <li> Building: Space Cooling</li>
                                        <li> Air Quality: Environmental norms</li>
                                        <li> Waste & others: Waste management</li>
                                        <li>Climate Finance: Fundings</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-7">    	
                            <div className="card-box heading-design">
                                <h4 className="header-title mt-0 design-1">Latest Development and Success Stories</h4>
                                <div className="widget-chart text-center">
                                <div className="row">
                                        <div className="col-xl-4">
                                            <ul className="text-left list-dashboard pl-2 mt-2">
                                                <li> Policy Announcement</li>
                                                <li> Implementing Projects</li>
                                                <li> Progress on NDC: Target Vs achievement</li>
                                            </ul>
                                        </div>
                                        <div className="col-xl-8">
                                            <div className="text-left dasboard-article-view">
                                                <div className="">
                                                    <h5>New Microgrid Is a Symbol of Resilience for Bad River Band</h5>
                                                    <p>Bad River Band of Lake Superior Tribe of Chippewa Indians complete the Ishkonige Nawadide Solar Microgrid
                                                    Project. <a href="#" target="_blank">More...</a></p>
                                                </div>
                                                <div className="dash-img">
                                                    <img src="assets/images/graphs/10.jpg" className="img-fluid"/>
                                                </div>
                                            </div>
                                            <ul className="text-left list-dashboard pl-2 mt-2">
                                                <li> 30,000 people are numerable because of extreme climate condition</li>
                                                <li> Government of Odisha Has mandated all industry to create equivalent green cover</li>
                                            </ul>
                                        </div>
                                </div>
                                </div>
                            </div>            
                        </div>
                    </div> 



                    </div>
                    </div>
                    <footer className="footer">
                    <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-6">
                    <a>Copyright &copy; 2015-2016 Technology Partner : TTC and Indev Consultancy Pvt. Ltd. </a> 
                    </div>
                    <div className="col-md-6">
                    <div className="text-md-right footer-links d-none d-sm-block">
                    <a href="javascript:void(0);">About Us</a>
                    <a href="javascript:void(0);">Help</a>
                    <a href="javascript:void(0);">Contact Us</a>
                    </div>
                    </div>
                    </div>
                    </div>
                    </footer>
                    </div>
                </div>
            </section>
            
        </React.Fragment>
    )
}

export default Dashboard;
